import { useState } from "react";
import { Typography, Input, Button } from "@material-tailwind/react";
import { EyeSlashIcon, EyeIcon } from "@heroicons/react/24/solid";
import BackgroundImage from "../../../assets/carousel-banner-images/index-carosel-img-one.webp";
import axios from "axios";

const login = async (username, password) => {
    try {
        const response = 
            await axios.post("/api/admins/login", 
            { username, password },
            { withCredentials: true });
        return true;
    } catch (error) {
        console.error("Login failed:", error.response?.data);
        return false;
    }
};

export function Login() {
    const [passwordShown, setPasswordShown] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);  // Track loading state
    const [loginError, setLoginError] = useState(""); // Track login error message

    const togglePasswordVisiblity = () => setPasswordShown((cur) => !cur);

    const validateForm = () => {
        let newErrors = {};
        if (!email) newErrors.email = "Email is required";
        if (!password) newErrors.password = "Password is required";
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoginError(""); // Reset any previous errors
        if (validateForm()) {
            setLoading(true);  // Set loading state to true
            const success = await login(email, password);
            setLoading(false); // Set loading state to false
            if (success) {
                // Redirect or show success message
                console.log("Login successful");
                // Optionally redirect the user to another page
                window.location.href = "/dashboard";  // Example
            } else {
                setLoginError("Invalid credentials, please try again.");
            }
        }
    };

    return (
        <section
            className="relative h-screen flex items-center justify-center bg-cover bg-center"
            style={{ backgroundImage: `url(${BackgroundImage})` }}
        >
            <div className="absolute inset-0 bg-black/30 backdrop-blur-sm"></div>
            <div className="relative bg-white/80 p-8 rounded-lg shadow-lg max-w-md w-full border border-gray-300">
                <Typography variant="h3" color="blue-gray" className="mb-2 text-center">
                    Sign In
                </Typography>
                <Typography className="mb-6 text-gray-600 font-normal text-center text-[18px]">
                    Enter your email and password to sign in
                </Typography>
                <form onSubmit={handleSubmit} className="text-left">
                    <div className="mb-6">
                        <label htmlFor="email">
                            <Typography
                                variant="small"
                                className="mb-2 block font-medium text-gray-900"
                            >
                                Email
                            </Typography>
                        </label>
                        <Input
                            id="email"
                            color="gray"
                            size="lg"
                            type="email"
                            name="email"
                            placeholder="name@mail.com"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                            labelProps={{ className: "hidden" }}
                        />
                        {errors.email && <Typography color="red" className="text-sm">{errors.email}</Typography>}
                    </div>
                    <div className="mb-6">
                        <label htmlFor="password">
                            <Typography
                                variant="small"
                                className="mb-2 block font-medium text-gray-900"
                            >
                                Password
                            </Typography>
                        </label>
                        <Input
                            size="lg"
                            placeholder="********"
                            labelProps={{ className: "hidden" }}
                            className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                            type={passwordShown ? "text" : "password"}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            icon={
                                <i onClick={togglePasswordVisiblity}>
                                    {passwordShown ? (
                                        <EyeIcon className="h-5 w-5 cursor-pointer" />
                                    ) : (
                                        <EyeSlashIcon className="h-5 w-5 cursor-pointer" />
                                    )}
                                </i>
                            }
                        />
                        {errors.password && <Typography color="red" className="text-sm">{errors.password}</Typography>}
                    </div>

                    {/* Show error message for failed login */}
                    {loginError && <Typography color="red" className="text-sm">{loginError}</Typography>}

                    <Button
                        type="submit"
                        color="gray"
                        size="lg"
                        className="mt-6 p-2"
                        fullWidth
                        disabled={loading} // Disable the button while loading
                    >
                        {loading ? "Signing In..." : "Sign In"}
                    </Button>

                    <div className="!mt-4 flex justify-end">
                        <Typography
                            as="a"
                            href="#"
                            color="blue-gray"
                            variant="small"
                            className="font-medium"
                        >
                            Forgot password?
                        </Typography>
                    </div>

                    <Button
                        variant="outlined"
                        size="lg"
                        className="mt-6 flex h-12 items-center justify-center gap-2"
                        fullWidth
                    >
                        <img
                            src="https://www.material-tailwind.com/logos/logo-google.png"
                            alt="google"
                            className="h-6 w-6"
                        />
                        Sign in with Google
                    </Button>
                    <Typography
                        variant="small"
                        color="gray"
                        className="!mt-4 text-center font-normal"
                    >
                        Not registered? <a href="#" className="font-medium text-gray-900">Create an account</a>
                    </Typography>
                </form>
            </div>
        </section>
    );
}

export default Login;
