import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import axios from "axios";
import { PublicRoutes } from "./Routes/PublicRoutes";
import { ThemeProvider } from "@material-tailwind/react";
import Login from "./Pages/Public/Login";
import AboutUs from "../src/Pages/Public/Home"
import { Spinner } from "@material-tailwind/react";

//import Dashboard from "./Pages/Private/Dashboard"; // Example private page

const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);

    useEffect(() => {
        const checkAuth = async () => {
            try {
                const response = await axios.get("api/admins/check-auth", {
                    withCredentials: true,
                });
                setIsAuthenticated(true);
                console.log(response.data.message);
            } catch (error) {
                setIsAuthenticated(false);
            }
        };

        checkAuth();
    }, []);

    return (
        <ThemeProvider>
            <Router>
                <Routes>
                    <Route path="/login" element={<Login />} />

                    {/* Show spinner only for private routes until auth is checked */}
                    <Route
                        path="/dashboard"
                        element={
                            isAuthenticated === null
                                ? <div className="flex justify-center items-center h-screen">
                                    <Spinner color="amber" className="h-32 w-32" />
                                </div>
                                : isAuthenticated
                                    ? <AboutUs />
                                    : <Navigate to="/login" />
                        }
                    />

                    {/* Public routes are always accessible */}
                    <Route path="/*" element={<PublicRoutes />} />
                </Routes>
            </Router>
        </ThemeProvider>
    );
};
export default App;
